import gatearmimg from '../assets/img/gatearmimg.png';
import React from 'react';
import * as moment from 'moment';
import { FaCamera, FaNetworkWired } from 'react-icons/fa';

import {
  Card,
  Media,
  Button,
  CardImg,
  CardHeader,
  CardTitle,
  CardText,
  CardGroup,
  CardSubtitle,
  CardBody,
} from 'reactstrap';

class GLMDeviceControl extends React.Component {
  state = {};
  constructor() {
    super();
    this.handleCaptureImage = this.handleCaptureImage.bind(this);
    this.state = {
      TotalDevices: 0,
    };
  }

  handleCaptureImage(id) {
    console.log('Command GLM Capture for ' + id);
    let data = { Command: 'CaptureImage', glmid: id };
    fetch('/api/glmcommands', {
      method: 'PUT', // or 'PUT'
      mode: 'cors',
      body: JSON.stringify(data), // data can be `string` or {object}!
      headers: {
        'Content-Type': 'application/json',
      },
    })
      .then(res => res.json())
      .then(response =>
        console.log('Success (GLM Command):', JSON.stringify(response)),
      )
      .catch(error => console.error('Error (GLM Command):', error));
  }

  handlePingCommand(id) {
    console.log('Command GLM Capture for ' + id);
    let data = { Command: 'Ping', glmid: id };
    fetch('/api/glmcommands', {
      method: 'PUT', // or 'PUT'
      mode: 'cors',
      body: JSON.stringify(data), // data can be `string` or {object}!
      headers: {
        'Content-Type': 'application/json',
      },
    })
      .then(res => res.json())
      .then(response =>
        console.log('Success (GLM Command):', JSON.stringify(response)),
      )
      .catch(error => console.error('Error (GLM Command):', error));
  }

  render() {
    return (
      <CardGroup>
        {this.props.glmdevices.map((device, index) => {
          return (
            <Card key={index}>
              <CardHeader className="text-center">
                {device.DeviceName}
              </CardHeader>
              <CardImg
                top
                width="50%"
                className="img-fluid"
                src={device.latestimage ? window.location.protocol + "//" +window.location.host+"/"+device.latestimage : gatearmimg}
              ></CardImg>
              <CardBody>
                <CardSubtitle>
                  <b>Installation Date:</b> {device.InstallationDate}
                </CardSubtitle>
                <CardSubtitle>
                  <b>Local IP:</b>
                  {device.WIFI_IP}
                </CardSubtitle>
                <CardSubtitle>
                  <b>Private IP:</b> {device.VPN_IP}
                </CardSubtitle>
                <CardSubtitle>
                  <b>Last Updated: </b>
                  {moment(device.UpdatedAt).fromNow()} at{' '}
                  {moment(device.UpdatedAt).format('LLL')}{' '}
                </CardSubtitle>
                <Button
                  color="primary"
                  size="sm"
                  onClick={() => this.handleCaptureImage(device.DeviceId)}
                >
                  <FaCamera> </FaCamera> Capture Image{' '}
                </Button>
                &nbsp;&nbsp;&nbsp;
                <Button
                  color="primary"
                  size="sm"
                  onClick={() => this.handlePingCommand(device.DeviceId)}
                >
                  {' '}
                  <FaNetworkWired></FaNetworkWired>Ping{' '}
                </Button>
              </CardBody>
            </Card>
          );
        })}
      </CardGroup>
    );
  }
}

export default GLMDeviceControl;
